.containerViewTicket {
    background: #fff;
    border-radius: 20px;
}

.containerButtonsTicket {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.buttonCancel {
    color: #bf3e3e !important ;
    border-color: #bf3e3e !important;
}

.buttonSend {
    background: #3a6b4e !important;
    border: hidden !important;
    color: #fff !important;
}


.containerMessages{
    max-height: 10rem;
    overflow-y: auto;

}

.containerButtonsTickets{
    justify-content: space-between;
}