.tableHead {
    background-color: #dfeff1 !important;
}

.tableBody {
    .MuiTableCell-body {
        border-bottom: 1px solid #3a6b4e !important;
    }
    .MuiTableRow-root {
        &:hover {
            cursor: pointer;
        }
    }
}


.tableContainer{
    max-height: 580px;
    .MuiTableCell-head{ 
        background-color: #DFEFF1 !important;
    }
}