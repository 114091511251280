.disCreateContainer {
    width: 100%;
    height: 100%;

    .disCreateInner {
        .title {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            font-family: "Lato", sans-serif;
            color: #3a6b4e;
            font-weight: bold;
            margin-top: 2.5%;
            margin-bottom: 1.5%;
        }

        .createContainer {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            .actualPath {
                display: flex;
                width: 55%;
                margin-left: 9%;
                color: #3A6B4E;
                font-family: "Lato", sans-serif;

                .previous {
                    margin-right: 5px;
                    cursor: pointer;
                }
            }
            .createFormContainer {
                height: 100%;
                width: 42vw;
                background-color: white;
                border-radius: 18px;    
                padding-top: 45px;
                padding-bottom: 30px;
                .createFormInner {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    .stepperContainer {
                        width: 35vw!important;
                        margin: 0 auto;

                        .MuiStepper-root {
                            padding: 0;
                        }

                        .MuiStepIcon-active, .MuiStepIcon-completed {
                            color: #8AC762;
                        }

                        .MuiTypography-root {
                            color: #3A6B4E;
                            font-family: "Lato", sans-serif;
                        }
                    }
                    .createFormColumn {
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: column;
                        justify-content: space-evenly;
                        align-items: center;
                        min-height: 300px;

                        .createNoteContainer {
                            .noteCreateLabel {
                                margin: 0;
                                font-weight: bold;
                                color: #3A6B4E;
                                margin-left: 15px;
                            }
                            .createNote {
                                resize: none;
                                border-color: #CFCFCF;
                                border-radius: 25px;
                                padding: 8px 15px;
                            }
                            .createNote:focus {
                                outline: none;
                            }
                        }
                    
                        .bankForm {
                            display: flex;
                            margin-top: 30px;
                        }
                    }
                    .createFormRow {
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        min-height: 300px;

                        .m0 {
                            margin: 10px;
                        }
                    }
                    .formButtons {
                        display: flex;
                        justify-content: space-evenly;     
                        align-items: center;             
                        height: 80px;
                        .btn {
                            border-radius: 20px !important;
                            font-family: "Lato", sans-serif;
                            font-weight: bold;
                            border-color: #70B2E5;
                            color: #70B2E5;
                            width: 130px;
                            display: flex;
                            justify-content: space-evenly;
                        }

                        .Mui-disabled {
                            background-color: #E9ECE8 !important;
                        }

                        .prevButton {
                            background-color: white;
                            border-style: solid;
                            border-width: 1px;
                            box-shadow: none;
                            font-size: 0.8em;
                            
                            .arrowLeftIcon {
                                transform: scale(0.8);
                                margin: 0;
                            }
                        }

                        .nextButton {
                            background-color: #70B2E5;
                            color: white;
                        }
                    }
                }
            }
        }
    }
     
    .MuiAlert-root {
        margin-top: 20px;
    }
}

.inputDummy {
    width: 26vw;
}

