.TextInputEcoColor {
    padding: 0.25rem 1rem;
   // margin-top: 5px;
    font-size: 1.1em;
    font-family: "Lato", sans-serif;
    color: #585858;
    border: none;
    box-sizing: border-box !important;
    outline: none;
}

.modeEditTextInputEcoColor {
    background: #eee;
    border-radius: 20px;
    border: 1px solid #cfcfcf;
}


.icon-eyes-password{
    cursor: pointer;
    display: block ;
    &:hover{
        color: #3a6b4e;
    }
}