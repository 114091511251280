.dashInput {
    margin: 0 10px;
    .dashInputLabel {
        margin: 0;
        margin-left: 25px;
        margin-bottom: 5px;
        color: #3A6B4E;
        font-family: "Lato", sans-serif;
        font-size: 0.9em;
        font-weight: bold;
    }
    .dashInput {
        outline: none;
        width: 14vw;
        height: 25px;
        border-radius: 20px;
        border-style: solid;
        border-width: 1px;
        border-color: #CFCFCF;
        padding-left: 20px;
    }
    .errorText {
        margin: 0;
        color: #BF673E;
        margin-left: 2vw;
        font-size: 0.9rem;
    }
    .errorTextSmall {
        margin-left: 0.75vw;
        color: #BF673E;
        font-size: 0.80rem;
    }
}