.loginPageContent {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 94vh;
  background-color: #3a6b4e;
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #3a6b4e;
  }

  .mundoLogoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30vw;
    height: 22vw;
    @media (max-width: 992px) {
      width: 30%;
      height: 25%;
    }

    .mundoLogo {
      width: 100%;
      height: 100%;
    }

    p {
      font-size: 2.6vw;
      margin: 0;
      letter-spacing: 8px;
      color: rgb(255, 255, 255);
      font-family: "Futura", sans-serif;
      margin-left: 2%;
      margin-top: 10%;

      @media (max-width: 992px) {
        font-size: 6vw;
        margin: 0;
        margin-left: 2%;
        margin-top: 10%;
      }
    }
  }
}


.loginSpinner {
  position: absolute;
  top: 0;
  left: 0;
}