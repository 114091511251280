.loginHeaderContainer {
    height: 6vh;
    display: flex;
    align-items: center;
    padding: 0 5%;
    background-color: white;

    @media (max-width: 992px) { 
        padding: 0;
        justify-content: center;
      }
    .loginLogo {
        height: 45%;
        cursor: pointer;
    }
}