.customButtonContainer {
  display: flex;
  justify-content: center;

  Button {
    width: 100%;
    height: 5.5vh;
    background-color: #f4841d;
    color: white;
    border-radius: 50px;
    text-transform: none;
    font-size: 100%;
    font-family: "Lato", sans-serif;
    font-weight: bold;
    letter-spacing: 2px;
  }

  Button:hover{
    background-color: #f4851dda ;
  }
}
