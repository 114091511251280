.sidebarContainer {
    width: 12vw;
    height: 100%;
    background-color: #3a6b4e;
    margin-left: 1vw;

    .sidebarInner {
        height: 100%;
        width: 100%;

        .blankSpace {
            height: 35px;
        }
    }
}
