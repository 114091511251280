.textAmountContainer {
    display: flex;

    .text {
        width: 100%;
        margin-left: 5%;
    }

    p {
        font-family: "Lato", sans-serif;
        color: #3a6b4e;
    }
}