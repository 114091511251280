.distriDetailContainer {
    width: 85%;
    height: 100%;

    .distriDetailInner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .title {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            .titleText {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                font-family: "Lato", sans-serif;
                color: #3a6b4e;
                font-weight: bold;
                margin-top: 13%;
                margin-bottom: 3%;
            }
        }

        .detailContent {
            width: 85%;

            .actualPath {
                display: flex;
                font-family: "Lato", sans-serif;
                color: #3a6b4e;
                .previous {
                    cursor: pointer;
                    margin-right: 5px;
                }
            }

            .detail {
                background-color: white;
                border-radius: 0px 18px 18px 18px;
                padding: 0 2vw;
                padding-bottom: 4vh;
                margin-bottom: 2%;

                .detailInner {
                    width: 100%;
                    background-color: white;

                    .titleAndButtons {
                        display: flex;
                        justify-content: flex-end;
                        margin-bottom: 10px;

                        .editDeleteButtons {
                            .editIcon {
                                transform: scale(1.2);
                                margin-top: 20px;
                                cursor: pointer;
                            }
                        }
                    }

                    .distributorDetail {
                        width: 100%;
                        height: 250px;
                        display: flex;
                        justify-content: space-around;
                        margin-bottom: 30px;

                        .column {
                            font-family: "Lato", sans-serif;
                            color: #585858;
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            
                            .info {
                                p:first-child {
                                    color: #3a6b4e;
                                    font-family: "Lato", sans-serif;
                                    font-size: 0.9em;
                                    font-weight: 600;
                                    margin: 0;
                                }
                                p:last-child {
                                    margin: 5px 0 0 15px;
                                    font-size: 1.1em;
                                    font-family: "Lato", sans-serif;
                                    color: #585858;
                                }

                                .stateIcon {
                                    transform: scale(1.2);
                                    margin: 5px 0 0 20px;
                                }

                                .contractTitle {
                                    margin-bottom: 35px !important;
                                }

                                .income {
                                    margin-top: 20px;
                                    position: relative;
                                    .incomeAmount {
                                        position: absolute;
                                        top: 10px;
                                        left: 8px;
                                        color: #585858;
                                        font-family: "Lato", sans-serif;
                                        font-size: 2em;
                                        font-weight: bold;
                                    }
                                }
                            }
                            .dummy {
                                height: 43px;
                                width: 182.3px;
                            }
                        }

                        .note {
                            .contactNoteTitle {
                                margin-top: 28px;
                                color: #3a6b4e;
                                font-family: "Lato", sans-serif;
                                font-size: 0.9em;
                                font-weight: 600;
                            }
                            .contactNote {
                                outline: none;
                                border-color: #d2d2d2;
                                resize: none;
                                border-radius: 18px;
                                padding: 15px;
                            }
                        }
                    }

                    .contractContainer {
                        display: flex;
                        justify-content: space-around;
                        width: 100%;
                        margin-bottom: 30px;

                        .column {
                            display: flex;
                            flex-direction: column;

                            .contractTitle {
                                color: #3a6b4e;
                                font-family: "Lato", sans-serif;
                                font-size: 0.9em;
                                font-weight: 600;
                                margin-bottom: 25px;
                            }

                            .contractSettings {
                                display: flex;
                                margin-left: 20px;

                                .contractIconContainer {
                                    margin-right: 25px;
                                    margin-top: 4px;
                                    margin-top: 20px;
                                    cursor: pointer;
                                }

                                .contractVDE {
                                    display: flex;
                                    flex-direction: column;
                                    margin-bottom: 50px;
                                    margin-top: 15px;
                               
                                    .row {  
                                        display: flex;
                                        cursor: pointer;
                                        .size {
                                            transform: scale(1.4);
                                        }

                                        .settingIconDiff {
                                            margin-left: 2.5px;
                                        }

                                        .settingText {
                                            margin: 0;
                                            margin-left: 15px;
                                            color: #585858;
                                            font-family: "Lato", sans-serif;
                                        }

                                        .settingTextDiff {
                                            margin: 0;
                                            margin-left: 11px;
                                            color: #585858;
                                            font-family: "Lato", sans-serif;
                                        }
                                    }

                                    .row:nth-child(1) {
                                        margin-left: 4px;
                                        margin-bottom: 12px;
                                    }

                                    .row:nth-child(2) {
                                        margin-left: 4px;
                                        margin-bottom: 12px;
                                    }
                                }
                            }

                            .contractPermission {
                                .titleRow {
                                    display: flex;
                                    
                                    .pTitle {
                                        width: 70px;
                                        height: 25px;
                                        text-align:  center;
                                        color: #3a6b4e;
                                        font-family: "Lato", sans-serif;
                                    }    
                                }
                                .permRow {
                                    display: flex;

                                    .pTitleLeft {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        height: 45px;
                                        width: 70px;
                                        color: #3a6b4e;
                                        font-family: "Lato", sans-serif;
                                    }

                                    .permIcon {
                                        width: 70px;
                                        height: 45px;
                                        transform: scale(0.65);
                                    }
                                }
                            }

                            .comBorder {
                                border: 3px solid #3A6B4E;
                                position: relative;
                                padding: 21px 26px;
                                border-radius: 35px 0 35px 0;
                                margin-left: 20px;
                                margin-top: 5px;
                                .comNumber {
                                    text-align: center;
                                    color: #3a6b4e;
                                    font-family: "Lato", sans-serif;
                                    font-weight: bold;
                                    font-size: 1.3em;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
