.basicInputContainer {
  position: relative;
  width: 100%;

  .label {
    font-weight: 600;
    font-family: "Lato", sans-serif;
    margin: 0 2% 0% 6%;
    color: #3a6b4e;

    @media (max-width: 992px) {
      font-weight: 400;
      font-size: 90%;
    }
  }

  .textField {
    font-size: 16px;
    height: 5.5vh;
    border: none;
    width: 86%;
    padding-left: 14%;
    margin-top: 1%;
  }

  .hasError {
    border-style: solid;
    border-width: 2px;
    border-color: #bf673e;
  }

  .textField:focus {
    outline: none;
  }

  .icon {
    position: absolute;
    left: 5%;
    top: 53%;
    font-size: 1.3rem;
    color: rgba(58, 107, 78, 0.65);
  }
}
