@each $name, $spacerLocal in $spacers {
    .ml-#{$name} {
        margin-left: $spacerLocal !important;
    }
    .mr-#{$name} {
        margin-right: $spacerLocal !important;
    }
    .mt-#{$name} {
        margin-top: $spacerLocal !important;
    }
    .mb-#{$name} {
        margin-bottom: $spacerLocal !important;
    }
    .mx-#{$name} {
        margin-left: $spacerLocal !important;
        margin-right: $spacerLocal !important;
    }
    .my-#{$name} {
        margin-top: $spacerLocal !important;
        margin-bottom: $spacerLocal !important;
    }
    .m-#{$name} {
        margin: $spacerLocal !important;
    }
}

.m-0 {
    margin: 0 !important;
}

@each $name, $spacerLocal in $spacers {
    .pl-#{$name} {
        padding-left: $spacerLocal !important;
    }
    .pr-#{$name} {
        padding-right: $spacerLocal !important;
    }
    .pt-#{$name} {
        padding-top: $spacerLocal !important;
    }
    .pb-#{$name} {
        padding-bottom: $spacerLocal !important;
    }
    .px-#{$name} {
        padding-left: $spacerLocal !important;
        padding-right: $spacerLocal !important;
    }
    .py-#{$name} {
        padding-top: $spacerLocal !important;
        padding-bottom: $spacerLocal !important;
    }
    .p-#{$name} {
        padding: $spacerLocal !important;
    }
}

.p-0 {
    padding: 0 !important;
}

.border-box {
    box-sizing: border-box !important;
}
