.listItemColor {
    list-style-type: none;
    display: grid;
    grid-template-columns: 1.5fr 6fr;
    box-sizing: border-box;
    color: #585858;

    &:hover {
        background: #82bce8;
        cursor: pointer;
        color: #fff !important;
    }
}

.iconColor {
    width: 3.0769rem;
    height  : 0.95rem;
    display: block;
    content: ' ';
    background: red;
    align-self: flex-start;

}
