.containerView {
    background-color: #fff;
    border-radius: 15px;
    border-top-left-radius: 0px;
    padding: 1rem 6rem;
    display: flex;
    flex-direction: column;
}

.containerTableDistributor {
    overflow-y: auto;
    max-height: 53vh;
}

.containerSelectAddressCommercial {
    min-width: 20vw;
}
