// stylelint-disable declaration-no-important

// Common values
@each $position in $positions {
    .position-#{$position} { position: $position !important; }
  }
  
  // Shorthand
  
  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $zindex-fixed;
  }
  
  .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-fixed;
  }
  
  .sticky-top {
    @supports (position: sticky) {
      position: sticky;
      top: 0;
      z-index: $zindex-sticky;
    }
  }


  @each $size, $value in $spacers {
	.top-#{$size} {
		top: $value;
	}
	.right-#{$size} {
		right: $value;
	}
	.bottom-#{$size} {
		bottom: $value;
	}
	.left-#{$size} {
		left: $value;
	}
}

.center {
	left: 50%;
	transform: translateX(-50%);
}

  .top-center{
    top:50% !important;
    transform: translateY(-50%) !important;
  }