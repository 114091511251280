    .clientDetailContainer {
        width: 85%;
        height: 100%;
        .clientDetailInnerLeft {
            display: flex;
            flex-direction: column;
            font-family: "Lato", sans-serif;
            color: #3a6b4e;
            font-weight: bold;
        }
        .clientDetailInner {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .actualPath {
                display: flex;
                font-family: "Lato", sans-serif;
                color: #3a6b4e;
                align-items: initial;
                .previous {
                    cursor: pointer;
                    margin-right: 5px;
                }
            }
            .consumedAmount {
                padding: 2%;
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
            .titleMid {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                color: #3a6b4e;
                font-family: Lato;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
            }
            .title {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                .titleText {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    font-family: "Lato", sans-serif;
                    color: #3a6b4e;
                    font-weight: bold;
                    margin-top: 13%;
                    margin-bottom: 3%;
                }
            }
            .detailContent {
                width: 100%;
                .detail {
                    background-color: white;
                    border-radius: 0px 18px 18px 18px;
                    padding: 0 2vw;
                    padding-bottom: 4vh;
                    margin-bottom: 2%;
                    .detailInner {
                        width: 100%;
                        background-color: white;
                        .titleAndButtons {
                            display: flex;
                            justify-content: flex-end;
                            .editDeleteButtons {
                                .editIcon {
                                    transform: scale(1.2);
                                    margin-top: 20px;
                                    cursor: pointer;
                                }
                                .addOpcion {
                                    color: #F4841D;
                                }
                            }
                        }
                        .long {
                            height: 500px!important;
                        }
                        .distributorDetail {
                            width: 100%;
                            height: 250px;
                            display: flex;
                            justify-content: space-evenly;
                            .column {
                                font-family: "Lato", sans-serif;
                                color: #585858;
                                height: 100%;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-evenly;
                                .info {
                                    p:first-child {
                                        color: #3a6b4e;
                                        font-family: "Lato", sans-serif;
                                        font-size: 0.9em;
                                        font-weight: 600;
                                        margin: 0;
                                    }
                                    p:last-child {
                                        margin: 5px 0 0 15px;
                                        font-size: 1.1em;
                                        font-family: "Lato", sans-serif;
                                        color: #585858;
                                    }
                                    .stateIcon {
                                        transform: scale(1.2);
                                        margin: 5px 0 0 20px;
                                    }
                                    .contractTitle {
                                        margin-bottom: 35px !important;
                                        color: #3a6b4e;
                                        font-family: "Lato", sans-serif;
                                    }
                                    .income {
                                        margin-top: 20px;
                                        position: relative;
                                        .incomeAmount {
                                            position: absolute;
                                            top: 10px;
                                            left: 8px;
                                            color: #585858;
                                            font-family: "Lato", sans-serif;
                                            font-size: 2em;
                                            font-weight: bold;
                                        }
                                    }
                                }
                                .dummy {
                                    height: 43px;
                                    width: 182.3px;
                                }
                            }
                            .note {
                                .contactNoteTitle {
                                    margin-top: 28px;
                                    color: #3a6b4e;
                                    font-family: "Lato", sans-serif;
                                    font-size: 0.9em;
                                    font-weight: 600;
                                }
                                .contactNote {
                                    outline: none;
                                    border-color: #d2d2d2;
                                    resize: none;
                                    border-radius: 18px;
                                    width: 290px;
                                    height: 100px;
                                    background-color: white;
                                }
                            }
                        }
                        .contractContainer {
                            display: flex;
                            width: 100%;
                            .column {
                                display: flex;
                                flex-direction: column;
                                .contractTitle {
                                    color: #3a6b4e;
                                    font-family: "Lato", sans-serif;
                                    font-size: 0.9em;
                                    font-weight: 600;
                                }
                                .contractSettings {
                                    display: flex;
                                    margin-left: 20px;
                                    .contractIconContainer {
                                        margin-right: 25px;
                                        margin-top: 4px;
                                        cursor: pointer;
                                    }
                                    .contractVDE {
                                        display: flex;
                                        flex-direction: column;
                                        margin-bottom: 50px;
                                        .row {
                                            display: flex;
                                            cursor: pointer;
                                            .size {
                                                transform: scale(1.4);
                                            }
                                            .sizeDownloadIcon {
                                                transform: scale(1);
                                            }
                                            .settingIconDiff {
                                                margin-left: 2.5px;
                                            }
                                            .settingText {
                                                margin: 0;
                                                margin-left: 15px;
                                                color: #585858;
                                                font-family: "Lato", sans-serif;
                                            }
                                            .settingTextDiff {
                                                margin: 0;
                                                margin-left: 11px;
                                                color: #585858;
                                                font-family: "Lato", sans-serif;
                                            }
                                        }
                                        .row:nth-child(1) {
                                            margin-left: 4px;
                                            margin-bottom: 12px;
                                        }
                                        .row:nth-child(2) {
                                            margin-left: 6px;
                                            margin-bottom: 12px;
                                        }
                                    }
                                }
                                .contractPermission {
                                    .titleRow {
                                        display: flex;
                                        .pTitle {
                                            width: 70px;
                                            height: 25px;
                                            text-align: center;
                                            color: #3a6b4e;
                                            font-family: "Lato", sans-serif;
                                        }
                                    }
                                    .permRow {
                                        display: flex;
                                        .pTitleLeft {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            height: 45px;
                                            width: 70px;
                                            color: #3a6b4e;
                                            font-family: "Lato", sans-serif;
                                        }
                                        .permIcon {
                                            width: 70px;
                                            height: 45px;
                                            transform: scale(0.65);
                                        }
                                    }
                                }
                                .comBorder {
                                    border: 3px solid #3A6B4E;
                                    position: relative;
                                    padding: 21px 26px;
                                    border-radius: 35px 0 35px 0;
                                    margin-left: 20px;
                                    margin-top: 5px;
                                    .comNumber {
                                        text-align: center;
                                        color: #3a6b4e;
                                        font-family: "Lato", sans-serif;
                                        font-weight: bold;
                                        font-size: 1.3em;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }