.totalIncomeContainer {
    background-color: #FFFFFF;
    border-radius: 20px;
    width: 100%;

    .totalIncomeInner {
        display: flex;
        flex-direction: column;
        padding: 2% 1%;
        padding-right: 5%;
        height: 100%;

        .titleAndButton {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 8%;

            .title {
                margin: 0;
                color: #3A6B4E;
                font-family: "Lato", sans-serif;
                font-weight: bold;
                padding-left: 4%;
            }

            .customSelect {
                select {
                    padding: 3px 15px;
                    border-color: #3A6B4E;
                    color: #3A6B4E;
                }

                select:focus {
                    outline: none;
                }
            }
        }

        .incomeContent {
            display: flex;
            justify-content: space-between;
            width: 100%;
          

            .graph {
                width: 67%;
            }

            .incomeInfo {
                width: 30%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                
                .title {
                    margin-bottom: 2%;
                    color: #3A6B4E;
                    font-family: "Lato", sans-serif;
                    font-weight: bold;
                }

                .incomeValue {
                    position: relative;

                    .incomeDesign {
                        height: 100%;
                        width: 100%;
                    }

                    .number {
                        top: 8%;
                        left: 6%;
                        margin: 0;
                        position: absolute;
                        color: #3A6B4E;
                        font-family: "Lato", sans-serif;
                        font-weight: bold;
                        font-size: 2.5vw;
                    }

                    .comparison {
                        top: 66%;
                        left: 35%;
                        margin: 0;
                        position: absolute;
                        color: #F4841E;
                        font-family: "Lato", sans-serif;
                        font-weight: 500;
                        font-size: 1vw;
                    }
                }
            }
        }
    }
}

.recharts-legend-item {
    display: none !important; 
}