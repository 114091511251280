.Scrollbar{
    scrollbar-width: thin;
}
.Scrollbar::-webkit-Scrollbar {
    background-color: transparent;
    width: 0.4em;
    height:5px;
}

/* background of the Scrollbar except button or resizer */
.Scrollbar::-webkit-Scrollbar-track {
    background-color: transparent;
}
.Scrollbar::-webkit-Scrollbar-track:hover {
    background-color: transparent;
}

/* Scrollbar itself */
.Scrollbar:hover::-webkit-Scrollbar-thumb {
    background-color: #537E64;
    border-radius: 5px;
}
.Scrollbar:hover::-webkit-Scrollbar-thumb:hover {
    background-color: #537E64;
}
.Scrollbar::-webkit-Scrollbar-thumb {
    background-color: transparent;
    border-radius: 5px;
}
.Scrollbar::-webkit-Scrollbar-thumb:hover {
    background-color: #537E64;
}
/* set button(top and bottom of the Scrollbar) */
.Scrollbar::-webkit-Scrollbar-button {
    display: none;
}

/* div box */

