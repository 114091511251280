.changeContainer {
    width: 25%;
    height: 70%;
    background-color: #b3c7bc;
    border-radius: 48px;
    @media (max-width: 992px) { 
      width: 90%;
      height: 80%;
      background-color: #b3c7bc;
    }
  
    .changeInner {
      padding: 0% 8%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
  
      .title {
        text-align: center;
  
        p {
          font-size: 1.5vw;
          margin: 0;
          color: rgb(255, 255, 255);
          font-family: "Futura", sans-serif;
          @media (max-width: 992px) { 
            font-size: 5vw;
          }
        }
      }
  
      .changeForm {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 60%;
  
        .textInputs {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: 100%;
          width: 100%;
  
          .errorMsg {
            color: #bf673e;
            font-size: 80%;
            margin: 0.5% 0 0 6%;
            height: 3px;
            @media (max-width: 992px) { 
              height: 0px;
              margin-bottom: 5%;
            }
          }
        }
      }
  
      .resetButton {
        display: flex;
        justify-content: center;
        Button {
          width: 100%;
          background-color: #4caf4fec;
          color: white;
        }
      }
  
      .resetButton {
        display: flex;
        justify-content: center;
  
        Button {
          width: 100%;
          height: 5vh !important;
          background-color: #f4841d;
          color: white;
          border-radius: 50px !important;
          text-transform: none;
          font-size: 100%;
          font-family: "Lato", sans-serif;
        }
      }
  
      .forgot {
        display: flex;
        justify-content: center;
        color: #3a6b4e;
  
        p {
          margin: 0;
        }
        p:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
  