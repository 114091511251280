.titlePrinter {
    text-align: center;
    margin-top: 80px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    color: #3A6B4E;
}

.edit {
    height: 700px !important;
}

.detailPrinter {
    background-color: white;
    width: 900px;
    height: 600px;
    margin-top: 8%;
    border-radius: 10px 10px 10px 10px;
    -moz-border-radius: 10px 10px 10px 10px;
    -webkit-border-radius: 10px 10px 10px 10px;
    border: 0px solid #000000;
    .deleteOption {
        .editDeleteButtons {
            display: flex;
            margin-top: 30px;
            margin-left: 90px;
            text-align: center;
            font-family: Lato;
            font-style: normal;
            font-weight: 600;
        }
    }
    .titleAndButtons {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
        .editDeleteButtons {
            margin-right: 50px;
            .editIcon {
                transform: scale(1.2);
                margin-top: 20px;
                cursor: pointer;
            }
        }
    }
    .columnHolder {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 80%;
        justify-content: center;
        justify-content: space-around;
        .column {
            justify-content: space-around;
            font-family: "Lato", sans-serif;
            color: #585858;
            height: 100%;
            display: flex;
            flex-direction: column;
            .info {
                p:first-child {
                    color: #3a6b4e;
                    font-family: "Lato", sans-serif;
                    font-size: 0.9em;
                    font-weight: 600;
                    margin: 0;
                }
                p:last-child {
                    margin: 5px 0 0 15px;
                    font-size: 1.1em;
                    font-family: "Lato", sans-serif;
                    color: #585858;
                }
                .stateIcon {
                    transform: scale(1.2);
                    margin: 5px 0 0 20px;
                }
                .contractTitle {
                    margin-bottom: 35px !important;
                }
                .income {
                    margin-top: 20px;
                    position: relative;
                    .incomeAmount {
                        position: absolute;
                        top: 10px;
                        left: 8px;
                        color: #585858;
                        font-family: "Lato", sans-serif;
                        font-size: 2em;
                        font-weight: bold;
                    }
                }
            }
            .searcherContainer {
                position: relative;
                .searcher {
                    position: relative;
                    height: 20px;
                    width: 20vw;
                    border-radius: 17px;
                    border-style: solid;
                    border-width: 0.1px;
                    border-color: #CFCFCF;
                    background-color: #F5F5F5;
                    padding-left: 20px;
                }
                .medium {
                    width: 17% !important;
                    height: 30px !important;
                    margin-left: 30px;
                    margin-top: 5px;
                }
                .fat {
                    width: 100% !important;
                    height: 30px !important;
                    background-color: white !important;
                }
                .fatSelect {
                    width: 100% !important;
                    height: 30px !important;
                    background-color: white !important;
                }
                .fatGrey {
                    width: 90% !important;
                    height: 30px !important;
                    background-color: #F5F5F5 !important;
                    margin-left: -20px;
                }
                .searcher:focus {
                    outline: none;
                }
                .icon {
                    position: absolute;
                    top: 25%;
                    left: 2%;
                }
                .dummy {
                    height: 43px;
                    width: 182.3px;
                }
            }
        }
    }
}