.loginContainer {
  width: 25%;
  height: 65%;
  background-color: #b3c7bc;
  border-radius: 48px;
  @media (max-width: 992px) { 
    width: 90%;
    height: 65%;
    background-color: #b3c7bc;
  }
  
  .loginInner {
    padding: 0% 8%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .title {
      text-align: center;
      p {
        font-size: 1.8vw;
        margin: 0;
        color: rgb(255, 255, 255);
        font-family: "Futura", sans-serif;
        @media (max-width: 992px) { 
          font-size: 5vw;
        }
      }
    }

    .loginForm {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 50%;

      .textInputs {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
        width: 100%;

        .errorMsg {
          color: #bf673e;
          font-size: 80%;
          margin: 0% 0 2% 6%;
          height: 3px;

          @media (max-width: 992px) { 
            height: 0px;
            margin-bottom: 5%;
          }
        }
      }
    }

    .loginButton {
      display: flex;
      justify-content: center;

      Button {
        width: 100%;
        height: 5.5vh !important;
        background-color: #f4841d;
        color: white;
        border-radius: 50px !important;
        text-transform: none;
        font-size: 100%;
        font-family: "Lato", sans-serif;
      }
    }

    .forgot {
      display: flex;
      justify-content: center;
      color: #3a6b4e;
      font-family: "Lato", sans-serif;
      p {
        margin: 0;
      }
      p:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

