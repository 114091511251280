.totalPrintContainer {
    background-color: white;
    width: 210px;
    height: 450px;
    border-radius: 20px;
    padding: 1.5% 3.5%; 

    .totalPrintInner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        
        .title {
            height: 15%;
            width: 100%;
            font-size: 1em;
            p {
                text-align: center;
                margin: 0;
                font-family: "Lato", sans-serif;
                color: #3a6b4e;
            }
        }
        .graph {
            width: 100%;
            height: 45%;
        }
        .textAmount {
            height: 40%;
            width: 90%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        }
    }
}
