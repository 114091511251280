.consumedContainer {
    background-color: white;
    border-radius: 10px;
    width: 12%;
    padding: 18px 10px;

    .consumedInner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        height: 100%;

        .title {
            p {
                margin: 0 !important;
                font-family: "Lato", sans-serif;
                margin: 0 2% 0% 6%;
                color: #3a6b4e;
            }
        }

        .img {
            margin: 6% 0;
        }

        .amount {
            p {
                margin: 0 !important;
                font-family: "Lato", sans-serif;
                margin: 0 2% 0% 6%;
                color: #3a6b4e;
                font-weight: bold;
                font-size: 1.5em;
            }
        }
    }
}
