.successDialog {

    .icon {
        transform: scale(1.3);
        border-bottom: 5px;
    }

    .MuiDialogContent-root {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 200px;
        // border-bottom: 0px;
    }
    
    .MuiPaper-root {
        border-radius: 46px !important;
    }
    
    .MuiTypography-root {
        color:#4E5E54 !important;
        font-size: 1.2em;
        font-family: "Lato", sans-serif;
    }

    .MuiDialogActions-root{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .MuiButton-label{
        display: flex;
        justify-content: center;
        align-items: center; 
        color:#FFFFFF !important;
        font-size: 1.2em;
        font-family: "Lato", sans-serif;
    }

    .MuiButton-containedPrimary{
        background-color: #70B2E5;
    }

    .MuiButton-containedSecondary{
        background-color:#797979;
    }

    .MuiButton-root{
        border-radius: 46px;
        margin-right: 10px;
    }
    

}