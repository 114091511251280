.card-container {
    width: 13rem !important;
    height: max-content !important;
    border-radius: 15px !important;
    padding: 2rem 1rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

    transition: 0.5s ease !important;

    &:hover {
        transform: scale(1.1);
    }
    > img {
        width: 9rem;
        height: 9rem;
    }
}
