.permissionContainer {
    height: 180px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
    
    .label {
        margin: 0;
        margin-left: 15px;
        margin-bottom: 5px;
        color: #3A6B4E;
        font-family: "Lato", sans-serif;
        font-size: 0.9em;
        font-weight: bold;
    }

    .permissions {
        height: 100%;
        width: 18vw;
        border-radius: 20px;
        border-style: solid;
        border-width: 1px;
        border-color: #CFCFCF;
        padding: 10px;
        .permissionsInner {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-around;
            height: 100%;
            
            .names {
                display: flex;
                justify-content: space-around;
                color: #3A6B4E;
                font-family: "Lato", sans-serif;
                width: 100%;
                text-align: center;
                margin-right: 25px;
                .dummy, .clients, .repairman {
                    width: 80px;
                }
            }

            .row {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                width: 100%;
            
                .text, .Bar {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 80px;
                    color: #3a6b4e;
                    font-family: "Lato", sans-serif;

                    .MuiIconButton-label {
                        color: white;
                    }
                    
                    .Mui-checked {
                        color:  #3a6b4e;
                       
                    }
                    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track  {
                       background-color:#8AC762;
                    }
                }
            }
        }
    }
}