.sideOption {
  width: 100%;
  background-color: #3a6b4e;
  padding: 2vh 0;
  transition: 0.3s;
  
  .sideOptionInner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .icon {
      .awesomeIcon {
        color: white;
        width: 100%;
      }
    }

    .text {
      text-align: center;
      p {
        font-family: "Futura", sans-serif;
        //color: #3A6B4E;
        color: white;
        margin: 5px 0;
      }
    }
  }
}

.sideOptionSelected {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2vh 0;

  .sideOptionInner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .icon {
      .awesomeIcon {
        color: #3a6b4e;
        width: 100%;
      }
    }

    .text {
      text-align: center;
      p {
        font-family: "Futura", sans-serif;
        color: #3a6b4e;
        font-weight: bold;
        margin: 5px 0;
      }
    }
  }
}

.sideOption:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.8);

  .icon {
    .awesomeIcon {
      color: #3a6b4e;
      width: 100%;
    }
  }

  .text {
    text-align: center;
    p {
      font-family: "Futura", sans-serif;
      color: #3a6b4e;
      font-weight: bold;
      margin: 5px 0;
    }
  }
}
