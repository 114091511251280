.buttonRemoveContainer {
    width: max-content;
    border: none;
    background: transparent;
    border-radius: 20px;
    cursor: pointer;
    padding: 0.5em 1em;
    &:focus {
        outline: none ;
    }
    &:hover {
        background: rgba(0, 0, 0, 0.04);
    }

    .text-button-edit {
        font-weight: bold;
        font-size: 1.3em ;
        margin-left: 0.1em;
    }
    .container-text-button-edit {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}
