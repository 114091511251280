.dashHeaderContainer {
    height: 3rem;
    background-color: white;
    width: calc(100% - 2rem);
    padding: 0 1rem;
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .logo {
        height: 100%;
        .ecoLogo {
            height: 4vh;
            width: 13vw;
        }
    }
    .logout {
        padding: 0;
        display: flex;
        align-items: center;
    }
    .profile {
    }
}

.containerDropdownAuth {
    display: inline-flex;
    transform: translateY(-15%);
    .MuiAvatar-root {
        font-size: 1rem;
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
    }
    .containerTextNameAndRole {
        display: flex;
        margin-right: 0.5rem;
        flex-direction: column;
        p {
            margin: 0;
            font-size: 0.79em;
            text-align: left;
            line-height: 0.5;
        }
    }
}
.containerPoperMenuAuth {
    .MuiPaper-rounded {
        border-radius: 15px !important;
    }
}
.containerListMenuAuth {
    min-width: 12rem !important;
    .MuiListItemText-root{
        margin-left: 0.5rem;
    }
}
