.totalUsers {
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 20px;

    .totalUsersInner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0.3% 3vw;
        align-items: center;
        height: 100%;

        .title {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #3A6B4E;
            font-family: "Lato", sans-serif;
            font-size: 1.2em;
            margin-right: 15px;
        }

        .total {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 150px;
            width: 180px;

            .text {
                width: 77%;
                height: 15%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                text-align: center;
                color: #B3B3B3;
                font-size: 0.8em;
                margin-bottom: 4px;
                .first, .second {
                    margin: 0;
                }
            }

            .infinite {
                position: relative;
                height: 100%;
                width: 100%;

                img {
                    height: 100%;
                    width: 100%;
                }
                
                .insideText {
                    color: #3A6B4E;
                    font-family: "Lato", sans-serif;
                    font-weight: bold;
                    font-size: 1.3em;
                    position: absolute;
                    top: 0;
                    right: 4px;
                    margin: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    align-items: center;
                    .red {
                       color: #BF673E;
                    }

                    .green {
                        color: #649D27;
                    }
                }
            }
        }
    }
}