    .extend {
        height: 800px !important;
        margin-bottom: 100px;
    }
    
    .billingHolder {
        display: flex;
        width: 100%;
        height: 600px;
        background-color: white;
        border-radius: 0px 15px 15px 15px;
        -moz-border-radius: 0px 15px 15px 15px;
        -webkit-border-radius: 0px 15px 15px 15px;
        border: 0px solid #000000;
        .topTable {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 60px;
            justify-content: center;
            justify-content: space-around;
            background-color: #DFEFF1;
            align-items: center;
            .form {
                margin-top: 10px;
                width: 20%;
                height: 30px;
                display: flex;
                justify-content: center;
                justify-content: space-around;
                align-items: center;
            }
            .formCell {
                margin-top: 10px;
                width: 10%;
                height: 30px;
                display: flex;
                justify-content: center;
                justify-content: space-around;
            }
        }
        .topTableWhite {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 60px;
            justify-content: center;
            justify-content: space-around;
            background-color: white;
            border-bottom: #3A6B4E 1px solid;
            .searcherContainerFlex {
                display: flex;
                .searcher {
                    width: 100%;
                    height: 30px;
                    border-radius: 17px;
                    border-style: solid;
                    border-width: 0.1px;
                    border-color: #CFCFCF;
                }
            }
            .form {
                margin-top: 10px;
                width: 20%;
                height: 30px;
                display: flex;
                justify-content: center;
                justify-content: space-around;
                align-items: center;
            }
            .formCell {
                margin-top: 10px;
                width: 10%;
                height: 30px;
                display: flex;
                justify-content: center;
                justify-content: space-around;
            }
        }
        .endTableWhite {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 60px;
            justify-content: center;
            justify-content: space-around;
            background-color: white;
            border-bottom: lightblue 1px solid;
            .searcherContainerFlex {
                display: flex;
                .searcher {
                    width: 100%;
                    height: 30px;
                    border-radius: 17px;
                    border-style: solid;
                    border-width: 0.1px;
                    border-color: #CFCFCF;
                }
            }
            .form {
                margin-top: 10px;
                width: 20%;
                height: 30px;
                display: flex;
                justify-content: center;
                justify-content: space-around;
                align-items: center;
            }
            .formCell {
                margin-top: 10px;
                width: 10%;
                height: 30px;
                display: flex;
                justify-content: center;
                justify-content: space-around;
            }
        }
        .columnPlacer {
            display: flex;
            flex-direction: column;
            width: 100%;
            .tableHeader {
                width: 100%;
                height: 75px;
                background-color: white;
                border-top-left-radius: 18px;
                border-top-right-radius: 18px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                margin-top: 20px;
                .createButton{
                    background-color: white;
                    color: #70B2E5;
                    border-color: #70B2E5;
                    border-radius: 20px;
                    text-transform: none;
                    width: 200px;
                    font-family: "Lato", sans-serif;
                }
                .createButtonSave{
                    background-color: #70B2E5;
                    color: white;
                    border-color: #70B2E5;
                    border-radius: 20px;
                    text-transform: none;
                    width: 200px;
                    font-family: "Lato", sans-serif;
                }
                .createButtonBillingImport {
                    color: #F4841D;
                    border-radius: 20px;
                    border: #F4841D solid 1px;
                    text-transform: none;
                    width: 23vh;
                    font-family: "Lato", sans-serif;
                    padding-left: 10px;
                    padding-right: 10px;
                }
                .calendarButton {
                    background-color: white;
                    color: #3A6B4E;
                    width: 200px;
                    height: 30px;
                    font-family: "Lato", sans-serif;
                    border-radius: 11px 11px 11px 11px;
                    -moz-border-radius: 11px 11px 11px 11px;
                    -webkit-border-radius: 11px 11px 11px 11px;
                    border: 1px solid #3A6B4E;
                    text-align: center;
                    font-size: 19px;
                }
            }
        }
        .columnHolder {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 80%;
            justify-content: center;
            justify-content: space-around;
            margin-top: 50px;
            .column {
                justify-content: space-around;
                font-family: "Lato", sans-serif;
                color: #585858;
                height: 100%;
                display: flex;
                flex-direction: column;
                .info {
                    p:first-child {
                        color: #3a6b4e;
                        font-family: "Lato", sans-serif;
                        font-size: 0.9em;
                        font-weight: 600;
                        margin: 0;
                    }
                    p:last-child {
                        margin: 5px 0 0 15px;
                        font-size: 1.1em;
                        font-family: "Lato", sans-serif;
                        color: #585858;
                    }
                    .stateIcon {
                        transform: scale(1.2);
                        margin: 5px 0 0 20px;
                    }
                    .contractTitle {
                        margin-bottom: 35px !important;
                    }
                    .income {
                        margin-top: 20px;
                        position: relative;
                        .incomeAmount {
                            position: absolute;
                            top: 10px;
                            left: 8px;
                            color: #585858;
                            font-family: "Lato", sans-serif;
                            font-size: 2em;
                            font-weight: bold;
                        }
                    }
                }
                .searcherContainer {
                    position: relative;
                    .searcher {
                        position: relative;
                        height: 20px;
                        width: 20vw;
                        border-radius: 17px;
                        border-style: solid;
                        border-width: 0.1px;
                        border-color: #CFCFCF;
                        background-color: #F5F5F5;
                        padding-left: 20px;
                    }
                    .medium {
                        width: 17% !important;
                        height: 30px !important;
                        margin-left: 30px;
                        margin-top: 5px;
                    }
                    .fat {
                        width: 100% !important;
                        height: 30px !important;
                        background-color: white !important;
                    }
                    .fatSelect {
                        width: 100% !important;
                        height: 30px !important;
                        background-color: white !important;
                    }
                    .fatGrey {
                        width: 90% !important;
                        height: 30px !important;
                        background-color: #F5F5F5 !important;
                        margin-left: -20px;
                    }
                    .searcher:focus {
                        outline: none;
                    }
                    .icon {
                        position: absolute;
                        top: 25%;
                        left: 2%;
                    }
                    .dummy {
                        height: 43px;
                        width: 182.3px;
                    }
                }
            }
        }
    }