.changePageContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 94vh;
    background-color: #3A6B4E;
}

.loginSpinner {
    position: absolute;
    top: 0;
    left: 0;
}