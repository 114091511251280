.containerTextStatus {
    padding: 5px 15px;
    width: max-content;
    border: 3px solid;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
}

.state-actived {
    border-color: #8ac762;
    span {
        color: #8ac762;
    }
}

.state-suspend {
    border-color: #e9c463;
    span {
        color: #e9c463;
    }
}


.state-eliminated {
    border-color: #bf3e3e;
    span {
        color: #bf3e3e;
    }
}