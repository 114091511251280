.listBox {
    width: 85%;

    .tableHeader {
        width: 100%;
        height: 75px;
        background-color: white;
        border-top-left-radius: 18px;
        border-top-right-radius: 18px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        
        .createButton {
            color: #F4841D;
            border-radius: 20px;
            border: #F4841D solid 1px;
            text-transform: none;
            width: 150px;
            font-family: "Lato", sans-serif;
            padding-left: 10px;
            padding-right: 10px;
        
        }
        .row {
            display: flex;
            flex-direction: row;
            .space {
                margin-left: 3px;
            }
            .calendarButton {
                background-color: white;
                color: #3A6B4E;
                width: 200px;
                height: 30px;
                font-family: "Lato", sans-serif;
                border-radius: 11px 11px 11px 11px;
                -moz-border-radius: 11px 11px 11px 11px;
                -webkit-border-radius: 11px 11px 11px 11px;
                border: 1px solid #3A6B4E;
                text-align: center;
                font-size: 19px;
            }
        }
        .discardButton {
            background-color: #BF673E;
            color: white;
            border-radius: 20px;
            text-transform: none;
            width: 150px;
            font-family: "Lato", sans-serif;
        }
        .billingButton {
            background-color: #3A6B4E;
            color: white;
            border-radius: 20px;
            text-transform: none;
            width: 150px;
            font-family: "Lato", sans-serif;
        }

        .searcherContainer {
            position: relative;

            .searcher {
                height: 28px;
                width: 15vw;
                border-radius: 20px;
                border-style: solid;
                border-width: 2px;
                border-color: #3a6b4e;
                background-color: #e9ece8;
                padding-left: 2rem;
            }

            .searcher:focus {
                outline: none;
            }

            .icon {
                position: absolute;
                top: 50%;
                left: 0.75rem;
                transform: translateY(-25%);                
            }
        }

        .customSelect {
            select {
                font-family: "Lato", sans-serif;
                width: 150px;
                padding: 8.5px 0;
                padding-left: 18px;
                color: white;
                background-color: #70B2E5;
                border-color: #70B2E5;
                border-radius: 20px;
              
                
                    option {
                        background-color: white;
                        font-size: 1.3em;
                        color: #585858;
                    }
            }

            select:focus {
                outline: none;
            }

            select:hover {
                cursor: pointer;
            }
        }
    }

    .tableContainer {
        max-height: 830px;
        margin-bottom: 20px;
        
        .sortIcon {
            margin-left: 10px;
        }

        .tableHead:nth-child(-n + 2) {
            cursor: pointer;
        }

        .table {
            .MuiTableCell-head{ 
                background-color: #DFEFF1 !important;
            }

            .tableBody {
                .MuiTableCell-body {
                    border-bottom: 1px solid #3a6b4e !important;
                }
                
                .tableRow {
                    cursor: pointer;
                    .stateG {
                        margin: 0 auto;
                        padding: 5px 0;
                        width: 70%;
                        height: 18px;
                        border: 3px solid #8AC762;
                        border-bottom-left-radius: 20px;
                        border-top-right-radius: 20px;
                    }
                    .stateY {
                        margin: 0 auto;
                        padding: 5px 0;
                        width: 85%;
                        height: 18px;
                        border: 3px solid #E9C463;
                        border-bottom-left-radius: 20px;
                        border-top-right-radius: 20px;
                    }
                    .activeOrInactive {
                        font-size: 1em;
                    }
                }
            }
        }
    }
}

