.customSelectContainer {
    select {
        font-family: "Lato", sans-serif;
        width: 150px;
        padding: 8.5px 0;
        padding-left: 18px;
        color: white;
        background-color: #70B2E5;
        border-color: #70B2E5;
        border-radius: 20px;
      
        
            option {
                background-color: white;
                font-size: 1.3em;
                color: #585858;
            }
    }

    select:focus {
        outline: none;
    }

    select:hover {
        cursor: pointer;
    }
}