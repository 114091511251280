@import "./variables.scss";

.text-white {
    color: $color-white !important;
}
.text-primary {
    color: #3a6b4e !important;
}

.text-secundary {
    color: #c9c4c4 !important;
}

.text-light {
    color: #b3b3b3 !important;
}

.text-green-light {
    color: #8ac762 !important;
}

.text-light-blue {
    color: #70b2e5 !important;
}

.text-red {
    color: $color-red !important;
}

.text-orange {
    color: #f4841e !important;
}

.text-gray-light {
    color: #585858 !important;
}

.text-font-bold {
    font-weight: bold !important;
}

.text-font-normal {
    font-weight: normal !important;
}

.font-weight-400 {
    font-weight: 400 !important;
}
.font-weight-500 {
    font-weight: 500 !important;
}
.font-weight-600 {
    font-weight: 600 !important;
}
.font-weight-700 {
    font-weight: 700 !important;
}
.font-weight-800 {
    font-weight: 800 !important;
}
.font-weight-900 {
    font-weight: 900 !important;
}

// Text decorations

.text-underline {
    text-decoration: underline;
}
.text-through {
    text-decoration: line-through;
}
.text-decoration-none {
    text-decoration: none !important;
}

// Text size

.text-xs {
    font-size: $font-size-xs !important;
}
.text-sm {
    font-size: $font-size-sm !important;
}
.text-lg {
    font-size: $font-size-lg !important;
}
.text-xl {
    font-size: $font-size-xl !important;
}

.text-center {
    text-align: center !important;
}

// Line heights

.lh-100 {
    line-height: 1;
}
.lh-110 {
    line-height: 1.1;
}
.lh-120 {
    line-height: 1.2;
}
.lh-130 {
    line-height: 1.3;
}
.lh-140 {
    line-height: 1.4;
}
.lh-150 {
    line-height: 1.5;
}
.lh-160 {
    line-height: 1.6;
}
.lh-170 {
    line-height: 1.7;
}
.lh-180 {
    line-height: 1.8;
}

// Letter spacings

.ls-1 {
    letter-spacing: 0.0625rem;
}
.ls-15 {
    letter-spacing: 0.09375rem;
}
.ls-2 {
    letter-spacing: 0.125rem;
}

.text-hidden-clock {
    
    &input[type="time"]::-webkit-calendar-picker-indicator {
        background: none;
    }
}
