.dashHomeContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 80px;

    .dashHomeInner {
        padding: 3% 8%;

        .consumedAmount {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        .totalRow {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 3% 0;
            width: 100%;

            .totalUsersColumn {
                width: 48%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin: 0 20px;
            }
        }

        .totalIncome {
            width: 100%;
        }
    }
}
