.holder {
  width: 100%;
  .billingHolder {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    border-radius: 15px 15px 15px 15px;
    -moz-border-radius: 15px 15px 15px 15px;
    -webkit-border-radius: 15px 15px 15px 15px;
    border: 0px solid #000000;
    .searcherContainerFlex {
      display: flex;
      .searcher {
        width: 170px;
        max-width: 100%;
        min-width: 100px;
        height: 30px;
        border-radius: 17px;
        border-style: solid;
        border-width: 0.1px;
        border-color: #cfcfcf;
        margin-top: 10px;
      }
    }
    .tableHeader {
      width: 100%;
      height: 50px;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 30px;
      .createButtonBilling {
        background-color: white;
        color: #70b2e5;
        border-radius: 20px;
        border: #70b2e5 1px solid;
        text-transform: none;
        width: 200px;
        font-family: "Lato", sans-serif;
        display: flex;
        margin-left: 15px;
        text-align: center;
      }
      .createButton2 {
        background-color: #70b2e5;
        color: white;
        border-radius: 20px;
        text-transform: none;
        width: 200px;
        font-family: "Lato", sans-serif;
        display: flex;
        margin-left: 15px;
        text-align: center;
      }
      .calendarButton {
        background-color: white;
        color: #3a6b4e;
        width: 200px;
        height: 30px;
        font-family: "Lato", sans-serif;
        border-radius: 11px 11px 11px 11px;
        -moz-border-radius: 11px 11px 11px 11px;
        -webkit-border-radius: 11px 11px 11px 11px;
        border: 1px solid #3a6b4e;
        text-align: center;
        font-size: 19px;
      }
    }
  }
}
