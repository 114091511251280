.containerView{
    background-color: #FFF;
    border-radius: 15px;
    border-top-left-radius: 0px;
    padding: 1rem 6rem;
    display: flex;
    flex-direction: column;
}

.containerTableDistributor{
    overflow-y : auto;
    max-height: 53vh;
}

.menuDistributorSelect{
    min-width: 15vw;
}