.searcherInputContainer {
    position: relative;
    .searcher {
        height: 28px;
        width: 15vw;
        border-radius: 20px;
        border-style: solid;
        border-width: 2px;
        border-color: #3a6b4e ;
        background-color: #e9ece8;
        padding-left: 2rem;
        cursor: pointer;
    }

    .searcher:focus {
        outline: none;
    }

    .icon {
        position: absolute;
        top: 50%;
        left: 0.75rem;
        transform: translateY(-50%);                
    }
}