.dashboardPageContainer {
  height: 100vh;
  width: 100vw;
  background-color: #e5e5e5;
  flex-direction: column;
  overflow-y: none !important;
  position: fixed;

  .content {
    display: flex;
    flex-direction: row;
    height: calc(100% - 60px);
    width: 100%;
    overflow-y: none !important;

    .panel {
      overflow-y: auto;
      width: calc(100% - 12vw);
    }
  
  }
  .sidebar{
    height: 100%;
    @media(max-height:966px){
      overflow-y: auto;
       
    }
  }

}
