.TexAreaEcoColor {
    padding: 0.5rem  1rem;
    border: none;
    font-family: "Lato", sans-serif;
    color: #585858;
    font-size: 1.1em;
    box-sizing: border-box !important;


    &:focus{
        outline:none;
    }
}

.modeEditTextAreaEcoColor {
    background: #EEE ;
    border-radius: 20px ;
    border:1px solid  #CFCFCF;
}
