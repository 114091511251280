.DashClientsContainer {
    width: 100%;
    height: 100%;
    .clientsInner {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .title {
            font-family: "Lato", sans-serif;
            color: #3a6b4e;
            font-weight: bold;
            margin-top: 2.5%;
            margin-bottom: 1.5%;
        }
    }
}