.forgotTempContainer {
  width: 28%;
  background-color: #b3c7bc;
  border-radius: 25px;
  padding: 2vw;

  @media (max-width: 992px) {
    padding: 4vw;
    width: 80%;
  }

  .forgotTempInner {
    height: 100%;
    .alert {
      margin-bottom: 10px;
    }
    .content {  
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 65vh;

      @media (max-width: 992px) {
        height: 67vh;
      }
      .iconContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        .unlockIcon {
          width: 28vh;
        }
      }
      .text {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .primaryText {
          width: 70%;
          font-size: 2vw;
          color: #4e5e54;
          font-weight: bold;
          font-family: "Lato", sans-serif;
          @media (max-width: 992px) {
            font-size: 4vw;
            width: 50%;
          }
          @media (max-width: 580px) {
            font-size: 6vw;
            width: 70%;
          }
          .firstText {
            display: flex;
            justify-content: flex-start;
            margin: 0;
          }
          .secondText {
            display: flex;
            justify-content: flex-end;
            margin: 0;
          }
        }
      }

      .subText {
        width: 80%;
        text-align: center;
        font-family: "Lato", sans-serif;
        color: #3a6b4e;
        font-size: 0.9vw;

        @media (max-width: 992px) {
          font-size: 2vw;
        }
        @media (max-width: 580px) {
          font-size: 2.9vw;
        }
      }

      .basicInput {
        width: 85%;
        
      }

      .errorMsg {
        color: #bf673e;
        font-size: 80%;
        margin: 0% 0 2% 6%;
        height: 3px;

        @media (max-width: 992px) {
          height: 0px;
          margin-bottom: 5%;
        }
      }

      .button {
        width: 85%;
      }
    }
  }
}
