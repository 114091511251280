.tabs {
    display: flex;
    .tab {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F5F5F5;
        width: 160px;
        border-radius: 18px 18px 0 0;
        margin-right: 3px;
        cursor: pointer;
        .tabInner { 
            padding: 12px 15px;
            text-align: center;
            p {
                margin: 0;
                color: #3A6B4E;
                font-weight: 700;
                font-family: "Lato", sans-serif;
                font-size: 0.9em;
            }
        }
    }
    .selected { 
        background-color: white;
    }
}
