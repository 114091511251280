.DashDistributorContainer {
    width: 100%;
    height: 100%;
    .distributorInner {
        .consumedAmount {
            padding: 2%;
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            .graficTickets {
                .title {
                    font-size: 50px;
                }
                .secondTitle {
                    font-family: Lato;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    color: #3A6B4E;
                }
                background-color: white;
                width: 250px;
                border-radius: 10px 10px 10px 10px;
                -moz-border-radius: 10px 10px 10px 10px;
                -webkit-border-radius: 10px 10px 10px 10px;
                border: 0px solid #000000;
                text-align: center;
                .cornerImg {
                    height: 30px;
                    margin-right: 170px;
                    margin-top: 20px;
                }
            }
        }
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .title {
            font-family: "Lato", sans-serif;
            color: #3a6b4e;
            font-weight: bold;
            margin-top: 2.5%;
            margin-bottom: 1.5%;
        }
    }
}