.SelectInputEcoColor {
    padding: 0.25rem 1rem;
    margin-top: 5px;
    font-size: 1.1em;
    font-family: "Lato", sans-serif;
    color: #585858;
    border: none;
    outline: none;
    box-sizing: border-box !important;
}

.modeEditSelectInputEcoColor {
    background: #eee;
    border-radius: 20px;
    border: 1px solid #cfcfcf;
}

.menuSelectInputContainer {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 2rem 0 rgba(#8898aa, 0.15);

    > ul {
        padding: 0px !important;
    }
}
.itemMenuSelectInputContainer {
    list-style-type: none;
    &:hover {
        background: #82bce8;
        cursor: pointer;
        color: #fff !important;
    }
}

.iconClearSelectInput {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: rotate(45deg) translateY(-50%);
    cursor: pointer;
}
