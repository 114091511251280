

// Contextual backgrounds

@mixin bg-variant($parent, $color, $ignore-warning: false) {
    #{$parent} {
      background-color: $color !important;
    }
  }
  
  @mixin bg-gradient-variant($parent, $color, $ignore-warning: false) {
    #{$parent} {
    }
  
  }
  



.bg-white{
    background: #fff !important;
}

.bg-primary{
    background: $primary !important;
}
.bg-gray-ecoColor{
    background: #E5E5E5 !important;
}
.bg-transparent{
    background: transparent!important;
}
//
// Backgrounds
//


@each $color, $value in $colors {
	@include bg-variant(".bg-#{$color}", $value);
}

@each $color, $value in $theme-colors {
	@include bg-gradient-variant(".bg-gradient-#{$color}", $value);
}

@each $color, $value in $colors {
	@include bg-gradient-variant(".bg-gradient-#{$color}", $value);
}


// Background colors with transparency




// Sections backgrounds



// Shape (svg) fill colors

@each $color, $value in $theme-colors {
    .fill-#{$color}  {
        fill: $value;
    }

    .stroke-#{$color}  {
        stroke: $value;
    }
}

.fill-opacity-8 {
	fill-opacity: .8;
}
