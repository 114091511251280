.DashDistributorContainer {
    width: 100%;
    height: 100%;
    .distributorInner {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .title {
            font-family: "Lato", sans-serif;
            color: #3a6b4e;
            font-weight: bold;
            margin-top: 2.5%;
            margin-bottom: 1.5%;
        }
    }

    .itemForPayment{
        padding-left: 2rem;
        color: #3a6b4e;
        font-weight: 500;
        line-height: 1.5rem;
        .titleItem{
            color: rgba(0, 0, 0, 0.87);
            font-size: 14px;
            text-align: left;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 550;
            line-height: 24px;
            letter-spacing: 0.01071em;
        }
    }
    .divFormPayment{
        height: 300px;
    }
}
#card-form{
    margin-top: 4.5rem;
    height: 350px;
}
.formSendPayment{
    display: none;
}