.btn-neutro {
    width: max-content;
    border: none;
    background: transparent;
    border-radius: 20px;
    cursor: pointer;
    padding: 0.75em 1em;
    text-align: center;
}

.btn-neutro:hover {
    background: rgba(0, 0, 0, 0.04);
}

.btn-neutro:focus {
    outline: none;
}
.btn-neutro:disabled {
    background: transparent !important;
}

.btn-pill {
    border-radius: 20px !important;
}

.btn-border {
    border: 1px solid !important;
}

.btn {
    border-radius: 20px !important;
    font-family: "Lato", sans-serif;
    font-weight: bold;
    border-color: #70b2e5 ;
    color: #70b2e5 ;
    min-width: 130px;
    &:hover {
        transform: translateY(-0.1em);
    }
}

.btn-outline-danger {
    border: 1px solid $danger;
    color: $danger;
    &:hover {
        background-color: $danger;
        color: $white;
    }
}

.button-primary {
    background-color: $primary;
    color: $white;

    &:hover {
        background-color: $primary;
        opacity: 0.8;
    }
}

//----- custom--------

.formButtons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // margin-top: 30px;
    height: 80px;

    .deleteIcon {
        display: flex;
        justify-content: left;
        margin-right: 350px;
    }

    .MuiButton-label {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Mui-disabled {
        background-color: #e9ece8 !important;
    }

    .prevButton {
        background-color: white;
        border-style: solid;
        border-width: 1px;
        box-shadow: none;
        font-size: 0.8em;
        margin-right: 40px;

        .arrowLeftIcon {
            transform: scale(0.8);
            margin-right: 5px;
        }
    }

    .nextButton {
        background-color: #70b2e5;
        color: white;
        width: 146px;
        height: 41px;
        font-size: 10px;
    }
}
