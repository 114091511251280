.addBankAccount {
    display: flex;
    margin-top: 20px;
    margin-bottom: 50px;
    
    .addIcon {
        cursor: pointer;
        transform: scale(1.2);
        margin-right: 10px;
    }
    p {
        margin: 0;
        cursor: pointer;
        font-weight: bold;
        color: #3A6B4E;
        font-family: "Lato", sans-serif;
        margin-right: 8vw;
    }
}