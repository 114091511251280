.circleBarContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .percent {
        height: 100%;
        width: 100%;

        svg {
            position: relative;
            height: 100%;

            .c1 {
                stroke-width: 4;
                stroke: #649d27;
                fill: none;
                transform: translate(5px, 5px);
                stroke-dasharray: 530;
                stroke-dashoffset: calc(530 - (530 * 80) / 100);
            }

            .c2 {
                stroke-width: 4;
                stroke: #bf673e;
                fill: none;
                transform: translate(5px, 5px);
                stroke-dasharray: 460;
                stroke-dashoffset: calc(460 - (460 * 50) / 100);
            }

            .c3 {
                stroke-width: 4;
                stroke: #70b2e5;
                fill: none;
                transform: translate(5px, 5px);
                stroke-dasharray: 390;
                stroke-dashoffset: calc(390 - (390 * 30) / 100);
            }
        }

        .number {
            .percentage {
                position: absolute;
                margin: 0;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #3A6B4E;
                font-size: 2em;
                font-weight: bolder;
                font-family: "Lato", sans-serif;
            }
        }
    }
}
