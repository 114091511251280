.DashDistributorContainer {
    width: 100%;

    .distributorInner {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .title {
            font-family: "Lato", sans-serif;
            color: #3a6b4e;
            font-weight: bold;
        }
    }
}

.card {
    border-top-left-radius: 20px !important ;
    border-top-right-radius: 20px !important ;

    padding-top: 1em ;

}

.containerHeaderTable {
    padding-top: 0.3846rem;
    padding-bottom: 1.1538rem;
  
}
